import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from '../components/atoms/link/link';
import { PageContainer } from '../components/atoms/page-container/page-container';
import { Footer } from '../components/molecules/footer/footer';
import NavBar from '../components/molecules/navbar/navbar';

const PrivacyPolicyPage = () => {
  const h1Style = 'font-medium text-3xl md:text-4xl mb-10';
  const h2Style = 'font-bold mt-6';

  return (
    <>
      <main>
        <Helmet>
          <title>Hummingbird - Privacy Policy</title>
          <meta
            name="description"
            content="Hummingbird's privacy policy. Hummingbird does not sell trainees’ personal data to advertising platforms, data brokers, or information resellers."
          />
        </Helmet>

        <NavBar />

        <PageContainer>
          <h1 className={h1Style}>Privacy</h1>
          <p className="my-11">Last updated: 04/17/2021</p>
          <h2 className={h2Style}>1. Introduction</h2>

          <p>Welcome to Hummingbird Tech, LLC</p>
          <p>
            Hummingbird Tech, LLC (“us”, “we”, or “our”) operates{' '}
            <Link to="/" className="font-bold">
              https://www.hummingbird.fit/
            </Link>{' '}
            and Hummingbird mobile application (hereinafter referred to as
            “Service”).
          </p>
          <p>
            Our Privacy Policy governs your visit to{' '}
            <Link to="/" className="font-bold">
              https://www.hummingbird.fit/
            </Link>{' '}
            and Hummingbird mobile application, and explains how we collect,
            safeguard and disclose information that results from your use of our
            Service.
          </p>
          <p>
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
          </p>
          <p>
            Our Terms and Conditions (“Terms”) govern all use of our Service and
            together with the Privacy Policy constitutes your agreement with us
            (“agreement”).
          </p>

          <h2 className={h2Style}>2. Definitions</h2>
          <p>
            SERVICE means the{' '}
            <Link to="/" className="font-bold">
              https://www.hummingbird.fit/
            </Link>{' '}
            website and Hummingbird mobile application operated by Hummingbird
            Tech, LLC
          </p>
          <p>
            PERSONAL DATA means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </p>
          <p>
            USAGE DATA is data collected automatically either generated by the
            use of Service or from Service infrastructure itself (for example,
            the duration of a page visit).
          </p>
          <p>
            COOKIES are small files stored on your device (computer or mobile
            device).
          </p>
          <p>
            DATA CONTROLLER means a natural or legal person who (either alone or
            jointly or in common with other persons) determines the purposes for
            which and the manner in which any personal data are, or are to be,
            processed. For the purpose of this Privacy Policy, we are a Data
            Controller of your data.
          </p>

          <p>
            DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
            person who processes the data on behalf of the Data Controller. We
            may use the services of various Service Providers in order to
            process your data more effectively.
          </p>

          <p>
            DATA SUBJECT is any living individual who is the subject of Personal
            Data.
          </p>

          <p>
            THE USER is the individual using our Service. The User corresponds
            to the Data Subject, who is the subject of Personal Data.
          </p>

          <h2 className={h2Style}>3. Information Collection and Use</h2>

          <p>
            e collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>

          <h2 className={h2Style}>4. Types of Data Collected</h2>

          <h3>Personal Data</h3>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
          </p>

          <ol>
            <li>(a) Email address</li>
            <li>(b) First name and last name</li>
            <li>(c) Cookies and Usage Data</li>
          </ol>

          <p>
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link or by
            emailing at support@hummingbird.fit.
          </p>

          <h3>Usage Data</h3>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through a mobile
            device (“Usage Data”).
          </p>

          <p>
            This Usage Data may include information such as your computer's
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>

          <p>
            When you access Service with a mobile device, this Usage Data may
            include information such as the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browser you
            use, unique device identifiers and other diagnostic data.
          </p>

          <h3>Tracking Cookies Data</h3>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>

          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
          </p>

          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>

          <p>Examples of Cookies we use:</p>

          <ol>
            <li>
              (a) Session Cookies: We use Session Cookies to operate our
              Service.
            </li>
            <li>
              (b) Preference Cookies: We use Preference Cookies to remember your
              preferences and various settings.
            </li>

            <li>
              (c) Security Cookies: We use Security Cookies for security
              purposes.
            </li>

            <li>
              (d) Advertising Cookies: Advertising Cookies are used to serve you
              with advertisements that may be relevant to you and your
              interests.
            </li>
          </ol>

          <h3>Other Data</h3>
          <p>
            While using our Service, we may also collect the following
            information: heart rate (average, high, low) and calories (average,
            total).
          </p>

          <h2 className={h2Style}>5. Use of Data</h2>

          <p>
            Hummingbird Tech, LLC uses the collected data for various purposes:
          </p>

          <ol>
            <li>(a) to provide and maintain our Service;</li>
            <li>(b) to notify you about changes to our Service;</li>
            <li>
              (c) to allow you to participate in interactive features of our
              Service when you choose to do so;
            </li>
            <li>(d) to provide customer support;</li>
            <li>
              (e) to gather analysis or valuable information so that we can
              improve our Service;
            </li>
            <li>(f) to monitor the usage of our Service;</li>
            <li>(g) to detect, prevent and address technical issues;</li>
            <li>(h) to fulfill any other purpose for which you provide it;</li>
            <li>
              (i) to carry out our obligations and enforce our rights arising
              from any contracts entered into between you and us, including for
              billing and collection;
            </li>
            <li>
              (j) to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </li>
            <li>
              (k) to provide you with news, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless you have opted not to receive such
              information;
            </li>
            <li>
              (l) in any other way we may describe when you provide the
              information;
            </li>
            <li>(m) for any other purpose with your consent.</li>
          </ol>

          <h2 className={h2Style}>6. Retention of Data</h2>

          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>

          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>

          <h2 className={h2Style}>7. Transfer of Data</h2>

          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>

          <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>

          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>

          <p>
            Hummingbird Tech, LLC will take all the steps reasonably necessary
            to ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organisation or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>

          <h2 className={h2Style}>8. Disclosure of Data</h2>

          <p>
            We may disclose personal information that we collect, or you
            provide:
          </p>

          <ol>
            <li>
              (a) Disclosure for Law Enforcement. Under certain circumstances,
              we may be required to disclose your Personal Data if required to
              do so by law or in response to valid requests by public
              authorities.
            </li>

            <li>
              (b) Business Transaction. If we or our subsidiaries are involved
              in a merger, acquisition or asset sale, your Personal Data may be
              transferred.
            </li>
            <li>(c) Other cases. We may disclose your information also:</li>
            <li>(i) to our subsidiaries and affiliates;</li>
            <li>
              (ii) to contractors, service providers, and other third parties we
              use to support our business;
            </li>
            <li>(iii) to fulfill the purpose for which you provide it;</li>
            <li>
              (iv) for the purpose of including your company’s logo on our
              website;
            </li>
            <li>
              (v) for any other purpose disclosed by us when you provide the
              information;
            </li>
            <li>(vi) with your consent in any other cases;</li>
            <li>
              (vii) if we believe disclosure is necessary or appropriate to
              protect the rights, property, or safety of the Company, our
              customers, or others.
            </li>
          </ol>

          <h2 className={h2Style}>9. Security of Data</h2>

          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>

          <h2 className={h2Style}>
            10. Your Data Protection Rights Under General Data Protection
            Regulation (GDPR)
          </h2>

          <p>
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR. – See more at{' '}
            <Link
              to="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
              target="_blank"
              className="font-bold"
            >
              https://eur-lex.europa.eu/eli/reg/2016/679/oj
            </Link>
          </p>

          <p>
            We aim to take reasonable steps to allow you to correct, amend,
            delete, or limit the use of your Personal Data.
          </p>

          <p>
            If you wish to be informed what Personal Data we hold about you and
            if you want it to be removed from our systems, please email us at{' '}
            <Link to="mailto:support@hummingbird.fit" className="font-bold">
              support@hummingbird.fit
            </Link>
            .
          </p>

          <p>
            In certain circumstances, you have the following data protection
            rights:
          </p>

          <ol>
            <li>
              (a) the right to access, update or to delete the information we
              have on you;
            </li>
            <li>
              (b) the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </li>
            <li>
              (c) the right to object. You have the right to object to our
              processing of your Personal Data;
            </li>
            <li>
              (d) the right of restriction. You have the right to request that
              we restrict the processing of your personal information;
            </li>
            <li>
              (e) the right to data portability. You have the right to be
              provided with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;
            </li>
            <li>
              (f) the right to withdraw consent. You also have the right to
              withdraw your consent at any time where we rely on your consent to
              process your personal information;
            </li>
          </ol>
          <p>
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data.
          </p>

          <p>
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>

          <h2 className={h2Style}>
            11. Your Data Protection Rights under the California Privacy
            Protection Act (CalOPPA)
          </h2>

          <p>
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require a person or
            company in the United States (and conceivable the world) that
            operates websites collecting personally identifiable information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals with whom it is being shared, and to comply with
            this policy. – See more at:{' '}
            <Link
              to="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
              target="_blank"
              className="font-bold"
            >
              https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
            </Link>
          </p>

          <p>According to CalOPPA we agree to the following:</p>

          <ol>
            <li>(a) users can visit our site anonymously;</li>
            <li>
              (b) our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the page specified above on the home page of
              our website;
            </li>
            <li>
              (c) users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </li>
            <li>
              (d) users are able to change their personal information by
              emailing us at support@hummingbird.fit.
            </li>
          </ol>
          <p>Our Policy on “Do Not Track” Signals:</p>

          <p>
            We honor Do Not Track signals and do not track, plant cookies, or
            use advertising when a Do Not Track browser mechanism is in place.
            Do Not Track is a preference you can set in your web browser to
            inform websites that you do not want to be tracked.
          </p>

          <p>
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </p>

          <h2 className={h2Style}>12. Service Providers</h2>

          <p>
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), provide Service on our behalf,
            perform Service-related services or assist us in analysing how our
            Service is used.
          </p>

          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>

          <h2 className={h2Style}>13. Analytics</h2>

          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>

          <h3>Google Analytics</h3>
          <p>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualise and personalise the ads of its own advertising
            network.
          </p>

          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:{' '}
            <Link
              to="https://policies.google.com/privacy?hl=en"
              target="_blank"
              className="font-bold"
            >
              https://policies.google.com/privacy?hl=en
            </Link>
            .
          </p>

          <p>
            We also encourage you to review the Google's policy for safeguarding
            your data:{' '}
            <Link
              to="https://support.google.com/analytics/answer/6004245"
              target="_blank"
              className="font-bold"
            >
              https://support.google.com/analytics/answer/6004245
            </Link>
          </p>

          <h3>Firebase</h3>
          <p>Firebase is analytics service provided by Google Inc.</p>

          <p>
            You may opt-out of certain Firebase features through your mobile
            device settings, such as your device advertising settings or by
            following the instructions provided by Google in their Privacy
            Policy:{' '}
            <Link
              to="https://policies.google.com/privacy?hl=en"
              target="_blank"
              className="font-bold"
            >
              https://policies.google.com/privacy?hl=en
            </Link>
          </p>

          <p>
            For more information on what type of information Firebase collects,
            please visit the Google Privacy Terms web page:{' '}
            <Link
              to="https://policies.google.com/privacy?hl=en"
              target="_blank"
              className="font-bold"
            >
              https://policies.google.com/privacy?hl=en
            </Link>
          </p>

          <h2 className={h2Style}>14. CI/CD tools</h2>

          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>

          <h3>GitHub</h3>

          <p>GitHub is provided by GitHub, Inc.</p>

          <p>
            GitHub is a development platform to host and review code, manage
            projects, and build software.
          </p>

          <p>
            For more information on what data GitHub collects for what purpose
            and how the protection of the data is ensured, please visit GitHub
            Privacy Policy page:{' '}
            <Link
              to="https://help.github.com/en/articles/github-privacy-statement."
              target="_blank"
              className="font-bold"
            >
              https://help.github.com/en/articles/github-privacy-statement.
            </Link>
          </p>

          <h2 className={h2Style}>15. Advertising</h2>

          <p>
            We may use third-party Service Providers to show advertisements to
            you to help support and maintain our Service.
          </p>

          <h3>Google AdSense DoubleClick Cookie</h3>
          <p>
            Google, as a third party vendor, uses cookies to serve ads on our
            Service. Google's use of the DoubleClick cookie enables it and its
            partners to serve ads to our users based on their visit to our
            Service or other websites on the Internet.
          </p>

          <p>
            You may opt out of the use of the DoubleClick Cookie for
            interest-based advertising by visiting the Google Ads Settings web
            page:{' '}
            <Link
              to="http://www.google.com/ads/preferences/"
              className="font-bold"
            >
              http://www.google.com/ads/preferences/
            </Link>
          </p>

          <h3>Bing Ads</h3>
          <p>Bing Ads is an advertising service provided by Microsoft Inc.</p>

          <p>
            You can opt-out from Bing Ads by following the instructions on Bing
            Ads Opt-out page:{' '}
            <Link
              to="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
              target="_blank"
              className="font-bold"
            >
              https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
            </Link>
          </p>

          <p>
            For more information about Bing Ads, please visit their Privacy
            Policy:{' '}
            <Link
              to="https://privacy.microsoft.com/en-us/PrivacyStatement"
              target="_blank"
              className="font-bold"
            >
              https://privacy.microsoft.com/en-us/PrivacyStatement
            </Link>
          </p>

          <h3>AdMob by Google</h3>
          <p>AdMob by Google is provided by Google Inc.</p>

          <p>
            You can opt-out from the AdMob by Google service by following the
            instructions described by Google:{' '}
            <Link
              to="https://support.google.com/ads/answer/2662922?hl=en"
              target="_blank"
              className="font-bold"
            >
              https://support.google.com/ads/answer/2662922?hl=en
            </Link>
          </p>

          <p>
            For more information on how Google uses the collected information,
            please visit the “How Google uses data when you use our partners'
            sites or app” page:{' '}
            <Link
              to="http://www.google.com/policies/privacy/partners/"
              className="font-bold"
            >
              http://www.google.com/policies/privacy/partners/
            </Link>
            or visit the Privacy Policy of Google:{' '}
            <Link
              to="http://www.google.com/policies/privacy/"
              className="font-bold"
            >
              http://www.google.com/policies/privacy/
            </Link>
          </p>

          <h2 className={h2Style}>16. Behavioral Remarketing</h2>

          <p>
            Hummingbird Tech, LLC uses remarketing services to advertise on
            third party websites to you after you visited our Service. We and
            our third-party vendors use cookies to inform, optimise and serve
            ads based on your past visits to our Service.
          </p>

          <h3>Google Ads (AdWords)</h3>
          <p>
            Google Ads (AdWords) remarketing service is provided by Google Inc.
          </p>

          <p>
            You can opt-out of Google Analytics for Display Advertising and
            customize the Google Display Network ads by visiting the Google Ads
            Settings page:{' '}
            <Link to="http://www.google.com/settings/ads" className="font-bold">
              http://www.google.com/settings/ads
            </Link>
          </p>

          <p>
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on –{' '}
            <Link
              to="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              className="font-bold"
            >
              https://tools.google.com/dlpage/gaoptout
            </Link>{' '}
            – for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics.
          </p>

          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:{' '}
            <Link
              to="https://policies.google.com/privacy?hl=en"
              target="_blank"
              className="font-bold"
            >
              https://policies.google.com/privacy?hl=en
            </Link>
          </p>

          <h3>Bing Ads Remarketing</h3>
          <p>Bing Ads remarketing service is provided by Microsoft Inc.</p>

          <p>
            You can opt-out of Bing Ads interest-based ads by following their
            instructions:{' '}
            <Link
              to="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
              target="_blank"
              className="font-bold"
            >
              https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
            </Link>
          </p>

          <p>
            You can learn more about the privacy practices and policies of
            Microsoft by visiting their Privacy Policy page:{' '}
            <Link
              to="https://privacy.microsoft.com/en-us/PrivacyStatement"
              target="_blank"
              className="font-bold"
            >
              https://privacy.microsoft.com/en-us/PrivacyStatement
            </Link>
          </p>

          <h3>Twitter</h3>
          <p>Twitter remarketing service is provided by Twitter Inc.</p>

          <p>
            You can opt-out from Twitter's interest-based ads by following their
            instructions:{' '}
            <Link
              to="https://support.twitter.com/articles/20170405"
              target="_blank"
              className="font-bold"
            >
              https://support.twitter.com/articles/20170405
            </Link>
          </p>

          <p>
            You can learn more about the privacy practices and policies of
            Twitter by visiting their Privacy Policy page:{' '}
            <Link to="https://twitter.com/privacy" className="font-bold">
              https://twitter.com/privacy
            </Link>
          </p>

          <h3>Facebook</h3>
          <p>Facebook remarketing service is provided by Facebook Inc.</p>

          <p>
            You can learn more about interest-based advertising from Facebook by
            visiting this page:{' '}
            <Link
              to="https://www.facebook.com/help/164968693837950"
              target="_blank"
              className="font-bold"
            >
              https://www.facebook.com/help/164968693837950
            </Link>
          </p>

          <p>
            To opt-out from Facebook's interest-based ads, follow these
            instructions from Facebook:{' '}
            <Link
              to="https://www.facebook.com/help/568137493302217"
              target="_blank"
              className="font-bold"
            >
              https://www.facebook.com/help/568137493302217
            </Link>
          </p>

          <p>
            Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance in the USA{' '}
            <Link to="http://www.aboutads.info/choices/" className="font-bold">
              http://www.aboutads.info/choices/
            </Link>
            , the Digital Advertising Alliance of Canada in Canada{' '}
            <Link to="http://youradchoices.ca/" className="font-bold">
              http://youradchoices.ca/
            </Link>{' '}
            or the European Interactive Digital Advertising Alliance in Europe{' '}
            <Link to="http://www.youronlinechoices.eu/" className="font-bold">
              http://www.youronlinechoices.eu/
            </Link>
            , or opt-out using your mobile device settings.
          </p>

          <p>
            For more information on the privacy practices of Facebook, please
            visit Facebook's Data Policy:{' '}
            <Link
              to="https://www.facebook.com/privacy/explanation"
              target="_blank"
              className="font-bold"
            >
              https://www.facebook.com/privacy/explanation
            </Link>
          </p>

          <h2 className={h2Style}>17. Payments</h2>

          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>

          <p>
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>

          <p>The payment processors we work with are:</p>

          <h3>PayPal or Braintree:</h3>
          <p>
            Their Privacy Policy can be viewed at{' '}
            <Link
              to="https://www.paypal.com/webapps/mpp/ua/privacy-full"
              target="_blank"
              className="font-bold"
            >
              https://www.paypal.com/webapps/mpp/ua/privacy-full
            </Link>
          </p>

          <h3>Stripe:</h3>
          <p>
            Their Privacy Policy can be viewed at:{' '}
            <Link
              target="_blank"
              to="https://stripe.com/us/privacy"
              className="font-bold"
            >
              https://stripe.com/us/privacy
            </Link>
          </p>

          <h2 className={h2Style}>18. Links to Other Sites</h2>

          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>

          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h2 className={h2Style}>19. Children's Privacy</h2>

          <p>
            Our Services are not intended for use by children under the age of
            18 (“Child” or “Children”).
          </p>

          <p>
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>

          <h2 className={h2Style}>20. Changes to This Privacy Policy</h2>

          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>

          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>

          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <h2 className={h2Style}>21. Contact Us</h2>

          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>

          <p>
            By email:{' '}
            <Link to="mailto:support@hummingbird.fit" className="font-bold">
              support@hummingbird.fit
            </Link>
            .
          </p>
        </PageContainer>
      </main>

      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
